import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Dhairya from "../../assets/Images/People/Dhairya.png";
import Shariq from "../../assets/Images/People/Shariq.png";
import Adithya from "../../assets/Images/People/Adithya.png";
import Arrow from "../../assets/Images/Arrow.svg";
import Aakarsh from "../../assets/Images/People/Aakarsh.png";
import Atharva from "../../assets/Images/People/Atharva.png";
import AakarshCard from "../../assets/Images/People/Card/AakarshCard.png";
import AdithyaCard from "../../assets/Images/People/Card/AdithyaCard.png";
// import AtharvaCard from "../../assets/Images/People/Card/AtharvaCard.png";
import ShariqCard from "../../assets/Images/People/Card/ShariqCard.png";
import Test1 from "../../assets/Images/SparkTeam.png";
import Test2 from "../../assets/Images/test2.jpg";

// import DhairyaCard from "../../assets/Images/People/Card/DhairyaCard.png";
import { useNavigate } from "react-router-dom";

const peopleData = [
  { name: "Adithya", image: Adithya, order: 3 },
  { name: "Aakarsh", image: Aakarsh, order: 1 },
  { name: "Shariq", image: Shariq, order: 2 },
  { name: "Dhairya", image: Dhairya, order: 5 },
  { name: "Atharva", image: Atharva, order: 4 },
];

const peopleDataDesc = [
  {
    name: "Shariq",
    image: ShariqCard,
    desc: `<span> Shariq</span> is a multi-disciplinary leader with global experience, across Tech Ventures, Private Equity, and Healthcare. He has built organisations both from the ground up and some scaled up across the Nordics, West Asia and India.  `,
    work: ["Investments", "Operatitons", "Strategy"],
    linkedIn: "https://www.linkedin.com/in/shariqsalar/",
    mailto: "mailto:shariq@hushl.in",
    meeting: "https://calendly.com/hushl-info/30min",
  },
  {
    name: "Aakarsh",
    image: AakarshCard,
    desc: `<span> Aakarsh</span>, an IIT Kharagpur alumnus, has a rich experience in big tech and startups, with a knack for building complex tech solutions. His work spans across India, Russia, Australia, and South East Asia.`,
    work: ["Tech", "operations", "Product"],
    linkedIn: "https://www.linkedin.com/in/aakarshyadav/",
    mailto: "mailto:aakarsh@hushl.in",
    meeting: "https://calendly.com/hushl-info/30min",
  },
  {
    name: "Adithya",
    image: AdithyaCard,
    desc: `<span> Adithya</span> is a Harvard alumnus with a diverse background in education, technology, and business, having worked across India, West Asia, East Africa, and North America.`,
    work: ["Operations", "Product", "Design"],
    linkedIn: "https://www.linkedin.com/in/adithya-narayanan-37403377/",
    mailto: "mailto:adithya@hushl.in",
    meeting: "https://calendly.com/hushl-info/30min",
  },
  // {
  //   name: "Dhairya",
  //   image: DhairyaCard,
  //   desc: `Meet <span> Dhairya</span>, our Chief Executive Officer and the brains behind Hushl's mission to rock the AI world! With a flair for innovation and a killer sense of style, Dhairya leads the charge with passion, creativity, and a hint of rebel spirit.`,
  //   work: ["Lead Engineer", "Designer", "Developer"],
  //   linkedIn: "https://www.linkedin.com/in/dhairyamarwah/",
  //   mailto: "mailto:dhairyamarwah@hushl.in",
  //   meeting: "https://calendly.com/hushl/30min",
  // },
  // {
  //   name: "Atharva",
  //   image: AtharvaCard,
  //   desc: `Meet <span> Atharva</span>, our Chief Executive Officer and the brains behind Hushl's mission to rock the AI world! With a flair for innovation and a killer sense of style, Atharva leads the charge with passion, creativity, and a hint of rebel spirit.`,
  //   work: ["Lead Engineer", "Backend", "Developer"],
  //   linkedIn: "https://www.linkedin.com/in/atharvasalokhe/",
  //   mailto: "mailto:atharva@hushl.in",
  //   meeting: "https://calendly.com/hushl/30min",
  // },
];

const Index = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const bubbles = peopleData.sort((a, b) => a.order - b.order);
    bubbles.forEach((bubble, index) => {
      setTimeout(() => {
        const element = document.getElementById(
          bubble.name.toLowerCase() + "-bubble"
        );
        if (element) {
          element.classList.add("fade-in");
        }
      }, index * 200); // Adjust the delay as needed
    });
  }, []);

  // const scrollToCard = (name) => {
  //   const cardElement = document.getElementById(name.toLowerCase() + "-card");
  //   if (cardElement) {
  //     const yOffset = -240; // Adjust the offset as needed
  //     const y =
  //       cardElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
  //     window.scrollTo({ top: y, behavior: "smooth" });
  //   }
  // };

  return (
    <>
      <Navbar />
      <div className="partner-wrap people-page-wrap">
        <div className="partner-page people-page">
          <h1 className="style-title1 gradient-text">People and Culture</h1>
          <p className="style-subtitle2">
            Hushl’s culture fosters creativity, collaboration, and a sense of
            belonging. Meet the team that brings it to life.
          </p>
          <div className="landing-button nabar-cta">
            <button
              className="secondary"
              onClick={() => {
                navigate("/partner");
              }}
            >
              Partner with Us
            </button>
            <button
              onClick={() => {
                navigate("/careers");
              }}
            >
              Join Our Team
            </button>
          </div>
        </div>
        {/* <div className="people-img-bubbles">
          {peopleData.map((person) => (
            <div
              key={person.name}
              id={person.name.toLowerCase() + "-bubble"}
              className={
                "people-img-bubbles-item " +
                person.name.toLowerCase() +
                "-bubble"
              }
              onClick={() => scrollToCard(person.name)}
            >
              <img src={person.image} alt="" />
            </div>
          ))}
        </div> */}
      </div>
      <div className="people-display-cards-wrap">
        <div className="people-display-cards">
          {peopleDataDesc.map((person) => {
            return (
              <div
                key={person.name}
                id={person.name.toLowerCase() + "-card"}
                className="people-display-card"
              >
                <div className="people-display-card-img">
                  <img src={person.image} alt="" />
                </div>
                <div className="people-display-card-text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: person.desc,
                    }}
                    className="style-subheader"
                  ></p>
                  {/* <div className="items">
                    {person.work.map((item) => {
                      return <div className="pill">{item}</div>;
                    })}
                  </div> */}
                  <div className="people-display-navigation">
                    <div className="home-nav-item">
                      <p
                        onClick={() => {
                          window.open(person.linkedIn, "_blank");
                        }}
                        className="cta-type1-text"
                      >
                        Connect on
                        <br />
                        <span>_Linkedin</span>
                      </p>
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="home-nav-item">
                      <p
                        onClick={() => {
                          window.open(
                            "https://calendly.com/hushl-info/30min",
                            "_blank",
                            "noopener noreferrer"
                          );
                        }}
                        className="cta-type1-text"
                      >
                        Schedule a
                        <br />
                        <span> _meet</span>
                      </p>
                      <img src={Arrow} alt="" />
                    </div>
                    <div className="home-nav-item">
                      <p
                        onClick={() => {
                          window.location.href = person.mailto;
                        }}
                        className="cta-type1-text"
                      >
                        Send him a
                        <br />
                        <span> _mail</span>
                      </p>
                      <img src={Arrow} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="clients">
        <div className="client-card">
          <div className="client-text">
            <h1 className="style-subheader">
              "When we started this journey, all I had was a strong insight. The
              Research and UX team at Hushl helped convert those insights into
              conviction, and their AI, product, design and engineering team
              have been phenomenal to work with to build the product to life."
            </h1>
            <span className="style-subtitle1">
              ~ David Hamlin, Founder & CEO, Wandery Switzerland
            </span>
          </div>
          <div className="cleint-img">
            <img src={Test2} alt="" />
            <div className="cleint-name">
              David <br />
              <span>_Hamblin</span>
            </div>
          </div>
        </div>

        <div className="client-card">
          <div className="client-text">
            <h1 className="style-subheader">
              "Every member of our team is deeply in sync with team Hushl. The
              process from ideation to launch has been so streamlined that we
              never felt that we were two different teams."
            </h1>
            <span className="style-subtitle1">
              ~ Mario Vista, Chief Product Officer, Spark
            </span>
          </div>
          <div className="cleint-img">
            <img src={Test1} alt="" />
            <div className="cleint-name">
              Team <br />
              <span>_Spark</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
