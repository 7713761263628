import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import DownArrowIcon from "../../assets/Images/DownArrowIcon.svg";
import Energy1 from "../../assets/Images/Industries/Energy1.png";
import Energy2 from "../../assets/Images/Industries/Energy2.png";
import "./internal.css";
import SolutionsContact from "../../components/Contact/SolutionsContact";
import { useNavigate } from "react-router-dom";
const Energy = () => {
  const navigate = useNavigate();
  const readMore = useRef(null);

  const scrollToReadMore = () => {
    if (readMore.current) {
      readMore.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar />
      <div className="internal-pages-landing">
        <div className="internal-pages-left">
          <h1 className="style-title2">
            HCAI is changing the <br /> landscape of{" "}
            <span className="gradient-text"> _Energy </span>
          </h1>
          <div className="buttons">
            <button
              onClick={() => {
                navigate("/white-papers");
              }}
            >
              Download Paper
            </button>
            <button onClick={scrollToReadMore} className="secondary">
              <img src={DownArrowIcon} alt="" />
              Read more
            </button>
          </div>
        </div>
        <div className="internal-pages-right">
          <img src={Energy1} alt="" />
        </div>
      </div>
      <div className="internal-second-section" ref={readMore}>
        <img src={Energy2} alt="" />
        <div className="internal-second-section-content">
          <h1 className="style-subheader">
            The energy industry is ripe for an advanced technological
            intervention.
          </h1>
          <p className="style-subtitle2">
            The energy sector is on the verge of a transformation that puts
            people at its core, integrating advanced technologies to streamline
            operations, boost sustainability, and adapt to future needs. This
            revolution, driven by renewable energy, smart grids, data analytics,
            and AI, is about more than technology - it’s about empowering
            individuals to shape a sustainable future. It aims to democratize
            access to clean, affordable energy, foster informed decision-making,
            and address global issues like climate change. As we approach this
            pivotal moment, it’s essential to harness this opportunity and
            ensure its advantages reach everyone.
          </p>
        </div>
      </div>
      <div className="internal-grad-section">
        <h1 className="style-subheader">
          “Artificial intelligence holds immense promise for tackling some of
          society’s most pressing challenges, from climate change to healthcare
          disparities. Let’s leverage AI responsibly to create a more equitable
          world.”
        </h1>
      </div>
      <div className="internal-contact">
        <SolutionsContact />
      </div>
      <Footer />
    </>
  );
};

export default Energy;
