import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Arrow from "../../assets/Images/Arrow.svg";
import WhiteArrow from "../../assets/Images/WhiteArrow.svg";
import "./careers.css";
import CareerContact from "../../components/Contact/CareerContact";

const Index = () => {
  const jobList = [
    {
      department: "Engineering",
      jobs: [
        {
          title: "DevOps Engineer",
          description:
            "As a DevOps Engineer at Hushl, you will play a crucial role in maintaining the infrastructure that supports our AI solutions. You will work closely with our development team to streamline our operations and processes, ensuring the smooth deployment of our AI products.",
          fullDesc: `
          
          
          <span>
          Location:</span> Hybrid<br />
          <span>Experience:</span> 3-5 years<br />
          <span> Job Description: </span><br />
          As a DevOps Engineer at Hushl, you will play a crucial role in maintaining the infrastructure that supports our AI solutions. You will work closely with our development team to streamline our operations and processes, ensuring the smooth deployment of our AI products. <br /><br />
          <span>Responsibilities:</span><br />
          Design, implement, and manage the infrastructure used by our AI solutions.<br />
          Automate and streamline our operations and deployment processes.<br />
          Collaborate with the development team to improve system reliability and performance.<br />
          Monitor system logs and activity to identify potential issues.<br />
          Implement security measures to protect our data and infrastructure.<br />
          Provide technical support and guidance to team members.<br /><br />
          <span>Qualifications:</span><br />
          Bachelor’s degree in Computer Science, Information Technology, or a related field.<br />
          Proven experience as a DevOps Engineer or similar role in software development and system administration.<br />
          Experience with databases, networks (LAN, WAN), and patch management.<br />
          Knowledge of scripting languages such as Java, Python, Ruby, Bash.<br />
          Experience with open-source technologies and cloud services (such as AWS, Google Cloud, or Azure).<br />
          Strong problem-solving skills and attention to detail.<br />
          Excellent communication and teamwork skills.<br /><br />
          <span>Preferred Qualifications:</span><br />
          Experience in an AI-focused environment.<br />
          Knowledge of machine learning model deployment.<br />
          Familiarity with containerization technologies like Docker and Kubernetes.<br />
          Experience with CI/CD pipelines.<br /><br />
          As a DevOps Engineer at Hushl, you will have the opportunity to work in a dynamic and innovative environment, where your skills will be valued and your creativity will be encouraged. We look forward to your application!`,
          requirements: [
            "Bachelor's degree in Computer Science",
            "Experience with JavaScript, Python, etc.",
          ],
        },
        {
          title: "Software  Engineer",
          description:
            "We’re in search of a software developer who finds motivation in overcoming obstacles, flourishes amidst innovation, and carries the ethos of a startup. Ideally, this individual would resonate with our commitment to exceed expectations and play a part in our endeavor to enhance user experiences with our innovative solutions.",
          fullDesc: `
          <span>
            Location:</span> Hybrid<br />
            <span>Experience:</span> 3-5 years<br />
            <span> Job Description: </span><br />
            We’re on the lookout for a Software Developer who is not only invigorated by challenges but also thrives in an innovative environment and carries the ethos of a startup. This role is ideal for someone who shares our dedication to surpassing expectations and has a keen interest in enhancing user experiences with our innovative solutions.<br /><br />
            <span>Responsibilities:</span><br />
            Design, code, test and manage various applications<br />
            Collaborate with the engineering team and product team to establish the best products<br />
            Be accountable for high-quality code that is scalable and reliable<br />
            Work on bug fixes and improving application performance<br /><br />
            <span>Requirements:</span><br />
            Bachelor’s degree in Computer Science or a related field<br />
            3-5 years of experience in software development<br />
            Strong knowledge of data structures, system design, and algorithms<br />
            Experience with open-source projects<br />
            Self-driven and a startup mindset<br />
            Excellent problem-solving skills<br />
            Good interpersonal and communication skills<br /><br />
            <span>Nice to Have:</span><br />
            Experience with Agile methodologies<br />
            Knowledge of a variety of programming languages<br /><br />
            At our company, we value diversity and always treat all employees and job applicants based on merit, qualifications, competence, and talent. We do not discriminate on the basis of race, religion, color, national origin, gender, sexual orientation, age, marital status, veteran status, or disability status.`,
          requirements: [
            "Experience with AWS, Docker, Kubernetes",
            "Scripting skills in Bash, Python, etc.",
          ],
        },
      ],
      type: "normal",
    },
    {
      department: "Design",
      jobs: [
        {
          title: "Graphic Design Intern",
          description:
            ` We're looking for a talented and enthusiastic Graphic Design Intern to join our design team. In this role, you'll gain valuable experience working on a variety of creative projects and contributing to the visual identity of Hushl.`,
          fullDesc: `
          
          
          <span>
          Location:</span> Hybrid<br />
          <span>Experience:</span> 3-5 years<br />
          <span> Job Description: </span><br /><br />

          We're looking for a talented and enthusiastic Graphic Design Intern to join our design team. In this role, you'll gain valuable experience working on a variety of creative projects and contributing to the visual identity of Hushl.
          <br /><br />

          <span>Responsibilities: </span> <br />
          ● Work directly with the Lead Designer on marketing materials (brochures, presentations, social media graphics, etc.) <br />
          ● Learn the design process firsthand, from brainstorming concepts to final execution<br />
          ● Utilize design software (Adobe Creative Suite preferred) to create high- quality visuals<br />
          ● Conduct research and source stock photos and illustrations<br />
          ● Prepare design mockups and presentations for Lead Designer review and
          client feedback<br />
          ● Stay updated on design trends and best practices<br /><br />
<span>
Qualifications:</span> <br />
● Strong understanding of design principles (composition, color theory, typography) <br />
● Portfolio showcasing your design skills and creativity (must-have) <br />
● Proficiency in Adobe Creative Suite (Photoshop, Illustrator, InDesign) <br />
● Excellent organizational skills and ability to prioritize tasks <br />
● A keen eye for detail and a commitment to quality <br />
● Strong communication and collaboration skills (you'll be working closely
with the Lead Designer) <br />
● A passion for learning and a willingness to take on new challenges<br /><br />
<span> Bonus Points:</span> <br />

● Experience with motion graphics or animation software<br />
● Knowledge of UI/UX design principles<br /><br />
<span> What We Offer:</span> <br />

● The opportunity to gain hands-on experience in a professional design setting<br />
● Learn the design process from start to finish<br />
● Master design software and refine your design skills<br />
● Contribute to the creation of impactful marketing materials<br /><br />
<span> If this sounds like you, apply using the form below!</span> <br />

`,
          requirements: [
            "Bachelor's degree in Computer Science",
            "Experience with JavaScript, Python, etc.",
          ],
        },
      ],
      type: "normal",
    },
    {
      department: "Marketing",
      jobs: [
        {
          title: "Digital Marketing Intern",
          description:
            "We're looking for a sharp and results-oriented Digital Marketing Intern to join our growing marketing team. In this role, you'll play a key part in developing and executing our paid advertising campaigns, helping us reach new audiences and achieve our marketing goals.",
          fullDesc: `
          
          
          <span>
          Location:</span> Hybrid<br />
          <span>Experience:</span> 3-5 years<br />
          <span> Job Description: </span><br /><br />
          <span>Responsibilities: </span> <br />
● Assist with the development and optimization of paid search campaigns (e.g., Google Ads)<br />
● Conduct keyword research and competitor analysis to identify high- performing opportunities<br />
● Develop compelling ad copy and creatives to maximize campaign effectiveness<br />
● Monitor campaign performance metrics and provide data-driven insights<br />
● Stay updated on the latest paid search trends and best practices<br />
● Work collaboratively with other marketing team members to achieve
campaign goals<br /><br />
<span>
Qualifications:</span> <br />
● Strong understanding of digital marketing principles and paid search advertising concepts<br />
● Experience with a paid search advertising platform (e.g., Google Ads) - must-have<br />
● Excellent analytical skills and the ability to interpret data<br />
● Strong written and verbal communication skills<br />
● A passion for learning and a willingness to take on new challenges<br />
● Ability to work independently and manage multiple tasks<br /><br />
<span> Bonus Points:</span> <br />

● Experience with other paid advertising platforms (e.g., Facebook Ads)<br /> ● Experience with conversion rate optimization (CRO)<br />
● A basic understanding of SEO principles<br /><br />
<span> What We Offer:</span> <br />

● Work alongside a talented and passionate marketing team<br />
● Learn the latest paid search strategies and tools<br />
● Make a real impact on our campaign performance and brand growth<br /><br />
<span> Apply using the form below!</span> <br />

`,
          requirements: [
            "Bachelor's degree in Computer Science",
            "Experience with JavaScript, Python, etc.",
          ],
        },
        {
          title: "Social Media Content Intern",
          description:
            `Do you have a finger on the pulse of social media trends and a passion for creating engaging content? Are you eager to learn about the exciting world of AI? If so, then we want you on our team!<br/><br/>
            Hushl is looking for a motivated and creative Social Media Content Intern to join our growing marketing team. In this role, you will play a key part in developing and executing our social media strategy, helping us connect with our audience and build brand awareness.`,
          fullDesc: `
          <span>
            Location:</span> Hybrid<br />
            <span>Experience:</span> 3-5 years<br />
            <span> Job Description: </span><br />
            Do you have a finger on the pulse of social media trends and a passion for creating engaging content? Are you eager to learn about the exciting world of AI? If so, then we want you on our team!<br/><br/>
            <span>Hushl</span> is looking for a motivated and creative Social Media Content Intern to join our growing marketing team. In this role, you will play a key part in developing and executing our social media strategy, helping us connect with our audience and build brand awareness.<br /><br />
            <span>Responsibilities: </span> <br />
            ● Brainstorm and create engaging content for various social media platforms (e.g., Facebook, Instagram, Twitter, LinkedIn)<br />
● Research and identify trending topics and hashtags relevant to the AI industry and our target audience<br />
● Develop and curate high-quality visual content (images, videos) using design tools (Canva, Photoshop experience a plus)<br />
● Schedule and publish content according to the social media calendar<br />
● Monitor social media conversations and engage with our followers
(respond to comments and messages)<br />
● Analyze social media performance metrics and report on key insights<br />
● Stay updated on the latest social media trends and best practices<br /><br />
            <span>
            Qualifications:</span> <br />
            ● Strong understanding of social media platforms and their unique audiences<br />
● Excellent written and verbal communication skills<br />
● Creative and a quick learner with a passion for storytelling<br />
● Ability to work independently and manage multiple tasks<br />
● Proficient in social media scheduling tools (e.g., Hootsuite, Buffer)<br />
● Experience with basic graphic design software a plus<br /><br />
            <span> Bonus Points:</span> <br />
            
            ● Knowledge of the AI industry
            <br />
            ● Experience with video editing software<br /><br />
            <span> What We Offer:</span> <br />
            ● The opportunity to gain valuable experience in a fast-paced and dynamic environment<br />
● Work alongside a talented and passionate team<br />
● Learn about the latest trends in social media and AI<br />
● Make a real impact on our brand growth<br /><br />
            <span> If you're ready to take your first step into the exciting world of social media and AI, we encourage you to apply!</span> <br />`,
          requirements: [
            "Experience with AWS, Docker, Kubernetes",
            "Scripting skills in Bash, Python, etc.",
          ],
        },
      ],
      type: "normal",
    },
    {
      department: "Product",
      jobs: null,
      type: "normal",
    },
    {
      department: "Finance",
      jobs: null,
      type: "normal",
    },
    {
      department: "Sales",
      jobs: null,
      type: "normal",
    },
    {
      department: "Open Application",
      jobs: null,
      type: "open",
    },
  ];

  const departments = jobList.map((item) => item.department);
  const defaultDepartment = departments[0]; // First department as default

  const [selectedDepartment, setSelectedDepartment] =
    useState(defaultDepartment);
  const [selectedJob, setSelectedJob] = useState(null);

  const handleDepartmentClick = (department) => {
    setSelectedDepartment(department);
    setSelectedJob(null); // Reset selected job when a new department is clicked
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  return (
    <>
      <Navbar />
      <div className="careers-page">
        <div className="careers-page-header">
          <h1 className="style-title2">
            Be a part of our _Team <br />
            <span className="style-subheader">
              Find your dream Job at Hushl <img src={WhiteArrow} alt="" />
            </span>
          </h1>
          <div className="careers-links">
            <div className="contact-page-links-list">
              <div
                onClick={() => {
                  window.open(
                    "mailto:aakarsh@hushl.in",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="contact-page-links-list-item"
              >
                <p className="cta-type1-text">
                  Send us an
                  <br /> <span>_Email</span>
                </p>
                <img src={WhiteArrow} alt="" />
              </div>
              <div
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/hushl/",
                    "_blank",
                    "noopener noreferrer"
                  );
                }}
                className="contact-page-links-list-item"
              >
                <p className="cta-type1-text">
                  Connect on <br /> <span>_Linkedin</span>
                </p>
                <img src={WhiteArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="career-page-container">
          <h1 className="style-subheader">Open roles</h1>
          <div className="career-page-container-flex">
            <div className="sidebar">
              {departments?.map((department, index) => {
                return (
                  <div
                    className={`sidebar-item ${
                      selectedDepartment === department ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => handleDepartmentClick(department)}
                  >
                    <p className="style-subtitle2">{department}</p>
                  </div>
                );
              })}
            </div>
            <div className="job-container">
              {selectedJob ? (
                <div className="job-desc-cont">
                  <h2 className="style-subheader">{selectedJob} </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: jobList
                        .find((item) => item.department === selectedDepartment)
                        .jobs.find((item) => item.title === selectedJob)
                        .fullDesc,
                    }}
                  ></p>
                  <CareerContact />
                </div>
              ) : (
                <div className="job-list">
                  {jobList.find(
                    (item) => item.department === selectedDepartment
                  ).jobs ? (
                    jobList
                      .find((item) => item.department === selectedDepartment)
                      .jobs.map((job, index) => (
                        <div
                          key={index}
                          className="job-item"
                          onClick={() => handleJobClick(job.title)}
                        >
                          <div className="job-item-content">
                            <h2 className="style-subheader">{job.title}</h2>
                            <p className="style-subtitle2">
                              {job.description.substring(0, 160)}... <br />
                            </p>
                          </div>
                          <div className="contact-page-links-list-item">
                            <p className="cta-type1-text">
                              Apply for <br />
                              <span>_this role</span>
                            </p>
                            <img src={Arrow} alt="" />
                          </div>
                        </div>
                      ))
                  ) : (
                    <div>No jobs available</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
