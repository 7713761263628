import React, { useState, useEffect } from 'react';
import './Human.css';
// import {Data} from './ModelData';
// import Modal from 'react-modal';
import Navbar from '../../components/Navbar';
import col10 from '../../assets/Images/Team/col-1/col-1-0.png';
import col11 from '../../assets/Images/Team/col-1/col-1-1.png';
import col12 from '../../assets/Images/Team/col-1/col-1-2.png';
import col13 from '../../assets/Images/Team/col-1/col-1-3.png';
import col14 from '../../assets/Images/Team/col-1/col-1-4.png';
import col15 from '../../assets/Images/Team/col-1/col-1-5.png';

import col20 from '../../assets/Images/Team/col-2/col-2-0.png';
import col21 from '../../assets/Images/Team/col-2/col-2-1-1.png';
import col22 from '../../assets/Images/Team/col-2/col-2-2.png';
import col23 from '../../assets/Images/Team/col-2/col-2-3.png';
import col24 from '../../assets/Images/Team/col-2/col-2-4.png';
import col25 from '../../assets/Images/Team/col-2/col-2-5.png';
import col26 from '../../assets/Images/Team/col-2/col-2-6.png';

import col30 from '../../assets/Images/Team/col-3/col-3-0.png';
import col31 from '../../assets/Images/Team/col-3/col-3-1.png';
import col32 from '../../assets/Images/Team/col-3/col-3-2.png';
import col33 from '../../assets/Images/Team/col-3/col-3-3.png';
import col34 from '../../assets/Images/Team/col-3/col-3-4.png';
import col35 from '../../assets/Images/Team/col-3/col-3-5.png';

import col40 from '../../assets/Images/Team/col-4/col-4-0.png';
import col41 from '../../assets/Images/Team/col-4/col-4-1.png';
import col42 from '../../assets/Images/Team/col-4/col-4-2.png';
import col43 from '../../assets/Images/Team/col-4/col-4-3.png';
import col44 from '../../assets/Images/Team/col-4/col-4-4.png';
import Footer from '../../components/Footer';
// import HumanSingleCard from './HumanSingleCard';

const Card = ({ image, onClick }) => (
    <div className="profile-card" onClick={onClick}>
        <img src={image} alt={image} />
    </div>
);


const Human = () => {
    // const [modalIsOpen, setIsOpen] = useState(false);
    // const [selectedProfile, setSelectedProfile] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isSmallScreen = windowWidth <= 600;
    const isMiddleScreen = windowWidth > 600 && windowWidth <= 1000;

    const profilesLargeScreen = [
                [
                    { id:col10,image: col10 },
                    { id:col11,image: col11 },
                    { id:col12,image: col12 },
                    { id:col13,image: col13 },
                    { id:col14,image: col14 },
                    { id:col15,image: col15 }
                ],
                [
                    { id:col20,image: col20 },
                    { id:col21,image: col21 },
                    { id:col26,image: col26 },
                    { id:col22,image: col22 },
                    { id:col23,image: col23 },
                    { id:col24,image: col24 },
                    { id:col25,image: col25 }
                ],
                [
                    { id:col30,image: col30},
                    { id:col31,image: col31 },
                    { id:col32,image: col32 },
                    { id:col33,image: col33 },
                    { id:col34,image: col34 },
                    { id:col35,image: col35 }
                ],
                [
                    { id:col40,image: col40 },
                    { id:col41,image: col41 },
                    { id:col42,image: col42 },
                    { id:col43,image: col43 },
                    { id:col44,image: col44 }
                ]
            ];
        
        
            const profilesMiddleScreen = [
                [ 
                    { id:col10,image: col10 },
                    { id:col30,image: col30 },
                    { id:col11,image: col11 },
                    { id:col20,image: col20 },
                    { id:col21,image : col21 },
                    { id:col31,image : col31 },
                    { id:col26,image: col26 },
                    { id:col22,image: col22 },
                    { id:col40,image: col40 },
                    { id:col41,image: col41 },
                    { id:col12,image: col12 },
                    { id:col32,image: col32 },
                    { id:col42,image: col42 }
                ],
                [
                    { id:col13,image: col13 },
                    { id:col23,image: col23 },
                    { id:col33,image: col33 },
                    { id:col43,image: col43 },
                    { id:col14,image: col14 },
                    { id:col24,image: col24 },
                    { id:col34,image: col34 },
                    { id:col44,image: col44 },
                    { id:col15,image: col15 },
                    { id:col25,image: col25 },
                    { id:col35,image: col35 },
                ]
            ];
        
           
            const profilesSmallScreen = [
                [ 
                    { id:col10,image: col10 },
                    { id:col30,image: col30 },
                    { id:col11,image: col11 },
                    { id:col20,image: col20 },
                    { id:col31,image : col31 },
                    { id:col26,image: col26 },
                    { id:col13,image: col13 },
                    { id:col23,image: col23 },
                    { id:col33,image: col33 },
                    { id:col43,image: col43 },
                    { id:col14,image: col14 },
                    { id:col22,image: col22 },
                ],
                [
                    { id:col21,image : col21 },
                    { id:col40,image: col40 },
                    { id:col24,image: col24 },
                    { id:col41,image: col41 },
                    {id:col12, image: col12 },
                    { id:col32,image: col32 },
                    { id:col42,image: col42 },
                    { id:col34,image: col34 },
                    { id:col44,image: col44 },
                    { id:col15,image: col15 },
                    { id:col25,image: col25 },
                    { id:col35,image: col35 },
                ]
            ];
        

    const profiles = isSmallScreen ? profilesSmallScreen : isMiddleScreen ? profilesMiddleScreen : profilesLargeScreen;

    // const customStyles = {
    //     content: {
    //         top: '55%',
    //         left: '50%',
    //         right: 'auto',
    //         bottom: 'auto',
    //         marginRight: '-50%',
    //         transform: 'translate(-50%, -50%)',
    //         borderRadius: '20px',
    //         width: '90%',
    //         height: '70%',
    //     },
    //     overlay: {
    //         backgroundColor: 'rgba(0, 0, 0, 0.75)',
    //         backdropFilter: 'blur(5px)',
    //         WebkitBackdropFilter: 'blur(5px)',
    //     }
    // };

    // function openModal(id) {
    //     const profile = Data.find(profile => profile.id === id);
    //     console.log(Data,"Data")
    //     console.log(profile,"profile")
    //     setSelectedProfile(profile);
    //     setIsOpen(true);
    // }

    // function closeModal() {
    //     setIsOpen(false);
    // }

    return (
        <>
            <Navbar />
            <div className='human-section'>
                <div className="human-text">
                    <h1 className="style-title2">
                        Humans of <span> _Hushl.ai</span>
                    </h1>
                    <div className="style-subtitle-wrapper-card">
                        <p className="style-subtitle1">
                            Every Hushler has a unique story of why they're here to {" "}
                            change the world of AI
                        </p>
                    </div>
                </div>
                <div className="profile-grid-layout">
                    {profiles.map((profileGroup, idx) => (
                        <div className="profile-layout" key={idx}>
                            {profileGroup.map((profile, index) => (
                                <Card
                                    key={index}
                                    id={profile.id}
                                    image={profile.image}
                                    // onClick={() => openModal(profile.id)}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />

            {/* <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Profile Modal"
            >
                {selectedProfile && (
                    <HumanSingleCard
                        id={selectedProfile.id}
                        name={selectedProfile.name}
                        designation={selectedProfile.designation}
                        image={selectedProfile.image}
                    />
                )}
            </Modal> */}
        </>
    );
};

export default Human
