import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import DownArrowIcon from "../../assets/Images/DownArrowIcon.svg";
import "./solutions.css";

import Img1 from "../../assets/Images/Industries/Energy.png";
import Solution from "../../assets/Images/solutions/solution.png";
import SolutionsContact from "../../components/Contact/SolutionsContact";

const DataAnalytics = () => {
  const contactFormRef = useRef(null);
  const readMore = useRef(null);

  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToReadMore = () => {
    if (readMore.current) {
      readMore.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar />
      <div className="solutions-page">
        <div className="solutions-header">
          <div className="solutions-header-left">
            <p className="style-subtitle2">Hushl’s Human-Centric AI </p>
            <h1 className="style-title1">
              Solution for <br /> <span>_Data Engineering + AI</span>
            </h1>
          </div>
          <div className="solutions-header-right">
            <p className="style-subtitle2">
              AI in Data Engineering accelerates data processing and uncovers
              hidden insights, enabling innovative data utilization.
            </p>
            <div className="buttons">
              <button onClick={scrollToContactForm}>
                Reach out to know more
              </button>
              <button className="secondary" onClick={scrollToReadMore}>
                <img src={DownArrowIcon} alt="" />
                Read more
              </button>
            </div>
          </div>
        </div>

        <div className="solution-img">
          <img src={Solution} alt="" />
        </div>
      </div>
      <div className="internal-second-section">
        <img src={Img1} alt="" />
        <div className="internal-second-section-content" ref={readMore}>
          <h1 className="style-subheader">
            From Raw Data to <span>AI Insights</span>
          </h1>
          <p className="style-subtitle2">
            Data engineering meticulously organizes and prepares vast amounts of
            data, serving as the bedrock for AI. This process reveals hidden
            patterns and trends, converting raw, unstructured data into
            actionable insights. As we traverse this digital landscape, the role
            of data engineers becomes increasingly vital. They are the
            cornerstone for successful AI integration in business, empowering
            organizations to make informed, data-driven decisions. This
            transformation is not just about managing data, but about driving
            strategic decision-making.
          </p>
        </div>
      </div>
      <div className="internal-grad-section">
        <h1 className="style-subheader">
          “Data Engineering is the enabler of data, and the future of our world
          rests on the efficient and secure movement of data.” 
        </h1>
      </div>
      <div ref={contactFormRef}>
        <SolutionsContact />
      </div>
      <Footer />
    </>
  );
};

export default DataAnalytics;
