import React, { useState } from "react";
import './WhitePaper.css'
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Hbet from "../../assets/WhitePapers/hbet.pdf";
import HbetImg from "../../assets/WhitePapers/hbet.png";
import WhiteArrow from "../../assets/Images/WhiteArrow.svg";
import Paper2 from "../../assets/WhitePapers/keynote-shneiderman-human-centered-ai.pdf";
import Paper3 from "../../assets/WhitePapers/National-AI-Engineering-Initiative.pdf";
import { BlurText } from '../Human-centric-ai/GridCard/GridCard';
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const WhitePaper1 = () => {
    const [numPages, setNumPages] = useState(null);
    const [, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const pdfs = [
        {
            name: "Human-Centered AI and Machine Learning",
            url: Hbet,
            source: "https://faculty.cc.gatech.edu/~riedl/pubs/hbet.pdf",
            title: "Human-Centered Artificial Intelligence and Machine Learning",
            author: "Mark O.Riedl",
            from: "School of Interactive Computing <strong>Georgia Institute of Technology</strong>",
        },
        {
            name: `Human-Centered AI:
      Bringing Design Science
      to AI Applications`,
            url: Paper2,
            source:
                "https://www.usf.edu/business/documents/desrist/keynote-shneiderman-human-centered-ai.pdf",
            title: `Human-Centered AI:
      Bringing Design Science
      to AI Applications`,
            author: "Ben Shneiderman",
            from: "University of <strong>Maryland<strong>",
        },
        {
            name: `National
        AI Engineering Initiative
        HumanCentered AI`,
            url: Paper3,
            source:
                "https://insights.sei.cmu.edu/documents/610/2021_019_001_735364.pdf",
            title: `National
      AI Engineering Initiative
      HumanCentered AI`,
            author: "Hollen Barmer",
            from: " Carnegie <strong>Mellon University</strong>",
        },
        {
            name: `Human-Centered Artificial Intelligence and Machine Learning`,
            url: Paper3,
            source:
                "https://insights.sei.cmu.edu/documents/610/2021_019_001_735364.pdf",
            title: `National
      AI Engineering Initiative
      HumanCentered AI`,
            author: "Hollen Barmer",
            from: " Carnegie <strong>Mellon University</strong>",
        },
        // Add more PDFs as needed
    ];

    const [selectedPdf, setSelectedPdf] = useState(Hbet);
    const [selectedPdfInfo, setSelectedPdfInfo] = useState(pdfs[0]);


    return (
        <>
            <Navbar />
            <div className="white-paper-containers">
                <div className="white-paper-container">
                    <div className="white-paper-card-left">
                        <div className='white-paper-card-left-text-card'>
                            <div className='white-paper-card-left-text-card-1'>
                                <BlurText text={"INSIGHTS & INNOVATION"} />
                                <div className="white-paper-text-gap">
                                    <h1 className='white-style-title2'>White Papers</h1>
                                </div>
                                <div className="white-paper-text-gap">
                                    <h1 className='white-paper-paragraph'>In the business of innovation, <span >the learning never stops.</span></h1>
                                </div>
                            </div>

                            <div className="white-paper-card-left-item">
                                {pdfs.map((pdf, index) => (
                                    <div
                                        className={`white-paper-card-left-item-1 ${selectedPdf === pdf.url ? "active" : ""
                                            }`}
                                        key={index}
                                        onClick={() => {
                                            setSelectedPdf(pdf.url);
                                            setSelectedPdfInfo(pdf);
                                        }}
                                    >
                                        <img src={HbetImg} alt="" />
                                        <p>{pdf.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>


                    <div className="white-reports">
                        <div className="white-paper-card-right">
                            <Document
                                file={selectedPdf}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        height={
                                            window.innerWidth > 1200
                                                ? 1100
                                                : window.innerWidth > 800
                                                    ? 900
                                                    : 800
                                        }
                                        width={
                                            window.innerWidth > 1200
                                                ? 1100
                                                : window.innerWidth > 800
                                                    ? 800
                                                    : 600
                                        }
                                    />
                                ))}
                            </Document>
                        </div>


                        <div className="white-paper-card-right-text">
                            <button
                                onClick={() => {
                                    window.open(
                                        selectedPdfInfo.source,
                                        "_blank",
                                        "noopener noreferrer"
                                    );
                                }}
                            >
                                Orignal Source <img src={WhiteArrow} alt="" />
                            </button>
                            <p>
                                {selectedPdfInfo.title}
                                <br />
                                {/* <span style={{color:"black", fontWeight:"500"}}>~{selectedPdfInfo.author}</span> */}
                                <span style={{ color: "black", fontWeight: "700" }}>~{selectedPdfInfo.author}</span>
                                <br />
                                <span style={{ color: "black", fontWeight: "500" }} dangerouslySetInnerHTML={{ __html: selectedPdfInfo.from }}></span>
                                <br />
                                {/* <span> {selectedPdfInfo.from}</span> */}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default WhitePaper1
