import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Arrow from "../../assets/Images/Arrow.svg";
import DownArrowIcon from "../../assets/Images/DownArrowIcon.svg";
import Img1 from "../../assets/Images/Industries/Energy.png";
// import "./internal.css";
import IndustriesContact from "../../components/Contact/IndustriesContact";
const index = () => {
  return (
    <>
      <Navbar />
      <div className="internal-pages-landing">
        <div className="internal-pages-left">
          <h1>
            Unleash the Power of Responsible AI:{" "}
            <span>Solutions Built to Thrive.</span>
          </h1>
          <p>
            We prioritize people's well-being and ethical principles at the
            heart of our AI initiatives. Our commitment extends beyond
            technological advancement; we aim to create meaningful and positive
            impacts on individuals, communities, and society as a whole.
          </p>

          <div className="buttons">
            <button>Ask your query</button>
            <button className="secondary">
              <img src={DownArrowIcon} alt="" />
              Read more
            </button>
          </div>
        </div>
        <div className="internal-pages-right">
          <img src={Img1} alt="" />
        </div>
      </div>
      <div className="impact-second-section">
        <div className="impact-second-section-top">
          <h1>
            Our Human-Centered <br /> <span> _AI Initiatives</span>
          </h1>
          <p>
            We prioritize people's well-being and ethical principles at the
            heart of our AI initiatives. Our commitment extends beyond
            technological advancement; we aim to create meaningful and positive
            impacts on individuals, communities, and society as a whole.
          </p>
        </div>
        <div className="impact-second-section-container">
          <div className="impact-second-section-container-item">
            <span>01</span>
            <h1>
              Human-Centric AI <br /> Development:
            </h1>
            <p>
              We don't just build features, we build trust. We craft AI that
              understands and respects your users, creating lasting impact.
            </p>
          </div>
          <div className="impact-second-section-container-item">
            <span>02</span>
            <h1>
              Ethical AI <br />
              Auditing:
            </h1>
            <p>
              Ensure your AI operates with transparency, fairness, and alignment
              with your values. We help you mitigate risk and build sustainable
              success.
            </p>
          </div>
          <div className="impact-second-section-container-item">
            <span>03</span>
            <h1>
              Sustainable AI <br /> Implementation:
            </h1>
            <p>
              We go beyond the technology. We help you minimize the
              environmental impact of your AI solutions throughout their
              lifecycle.
            </p>
          </div>
        </div>
      </div>
      <div className="impact-navigation">
        <h1>
          Together, we can harness the potential of AI to create a brighter,
          more inclusive future for all.{" "}
          <span>
            Join us in championing human-centered AI principles and shaping a
            world where technology serves{" "}
            <span> humanity's best interests.</span>
          </span>
        </h1>
        <div className="impact-navigation-items impact-nav-flex">
          <div className="impact-navigation-item">
            <h2>
              Experience our <br />{" "}
              <span> human-centered AI solutions in action</span>
            </h2>
            <img src={Arrow} alt="" />
          </div>
          {/* <div className="impact-navigation-item">
            <h2>
              Learn more about our{" "}
              <span> commitment to ethical AI principles</span>
            </h2>
            <img src={Arrow} alt="" />
          </div> */}
          <div className="impact-navigation-item">
            <h2>
              Join our community <br />
              <span> and collaborate on meaningful AI projects</span>
            </h2>
            <img src={Arrow} alt="" />
          </div>
        </div>
      </div>
      <div className="internal-grad-section">
        <h1>
          We’re not building the future of AI. <br /> We’re Building a better
          future with Humans.
        </h1>
      </div>
      <div className="internal-contact">
        <IndustriesContact title="If you have any support questions or would like to know more about our how we can help your industry, please get in touch." />
      </div>
      <Footer />
    </>
  );
};

export default index;
