import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import About from "../pages/About";
import Careers from "../pages/Careers";
import People from "../pages/People";
import Impact from "../pages/Impact";
import Thesis from "../pages/Thesis";
import Privacy from "../pages/Terms/Privacy";
import Terms from "../pages/Terms/Terms";
// import HCAI from "../pages/Resources/HCAI";
import Reports from "../pages/Resources/Reports";
// import WhitePapers from "../pages/Resources/WhitePapers";
import HeavyIndustries from "../pages/Industries/HeavyIndustries";
import Healthcare from "../pages/Industries/Healthcare";
import Investments from "../pages/Industries/Investments";
import Energy from "../pages/Industries/Energy";
import DataAnalytics from "../pages/Solutions/DataAnalytics";
import ProcessAutomation from "../pages/Solutions/ProcessAutomation";
import ProductDevelopment from "../pages/Solutions/ProductDevelopment";
import Partner from "../pages/Contact/Partner";
import Products from "../pages/Products/Products";
import FoundersProgram from "../pages/FoundersProgram/FoundersProgram";
import Human from "../pages/Humans/Human";
import HumanAI from "../pages/Human-centric-ai/HumanAI";
import WhitePaper1 from "../pages/Resources/WhitePaper1";
// import HumanSingleCard from "../pages/Humans/HumanSingleCard";

export default function Navigation() {
  const routes = [
    {
      path: "/",
      exact: true,
      element: <Home />,
      private: false,
    },
    {
      path: "/contact",
      exact: true,
      element: <Contact />,
      private: false,
    },
    {
      path: "/about",
      exact: true,
      element: <About />,
      private: false,
    },
    {
      path: "/people",
      exact: true,
      element: <People />,
      private: false,
    },
    {
      path: "/partner",
      exact: true,
      element: <Partner />,
      private: false,
    },
    {
      path: "/impact",
      exact: true,
      element: <Impact />,
      private: false,
    },
    {
      path: "/careers",
      exact: true,
      element: <Careers />,
      private: false,
    },
    // {
    //   path: "/human-centric-ai",
    //   exact: true,
    //   element: <HCAI />,
    //   private: false,
    // },
    {
      path: `/human-centric-ai`,
      exact: true,
      element: <HumanAI />,
      private: false,
    },
    {
      path: "/founders-program",
      exact: true,
      element: <FoundersProgram />,
      private: false,
    },
    {
      path: "/reports",
      exact: true,
      element: <Reports />,
      private: false,
    },
    // {
    //   path: "/white-papers",
    //   exact: true,
    //   element: <WhitePapers />,
    //   private: false,
    // },
    {
      path: "/white-papers",
      exact: true,
      element: <WhitePaper1 />,
      private: false,
    },
    {
      path: "/products/hushl-for-professionals",
      exact: true,
      element: <Products />,
      private: false,
    },
    {
      path: "/products/hushl-for-enterprises",
      exact: true,
      element: <Products />,
      private: false,
    },
    {
      path: "/products/hushl-for-founders",
      exact: true,
      element: <Products />,
      private: false,
    },
    {
      path: "/solutions/data-engineering",
      exact: true,
      element: <DataAnalytics />,
      private: false,
    },
    {
      path: "/solutions/process-automation",
      exact: true,
      element: <ProcessAutomation />,
      private: false,
    },
    {
      path: "/solutions/product-development",
      exact: true,
      element: <ProductDevelopment />,
      private: false,
    },
    {
      path: "/industries/energy",
      exact: true,
      element: <Energy />,
      private: false,
    },
    {
      path: "/industries/investments",
      exact: true,
      element: <Investments />,
      private: false,
    },
    {
      path: "/industries/healthcare",
      exact: true,
      element: <Healthcare />,
      private: false,
    },
    {
      path: "/industries/heavy-industries",
      exact: true,
      element: <HeavyIndustries />,
      private: false,
    },
    {
      path: "/thesis",
      exact: true,
      element: <Thesis />,
      private: false,
    },
    {
      path: "/terms-of-service",
      exact: true,
      element: <Terms />,
      private: false,
    },
    {
      path: "/privacy-policy",
      exact: true,
      element: <Privacy />,
      private: false,
    },
    {
      path: "/human",
      exact: true,
      element: <Human />,
      private: false,
    },
  ];
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <>
            <Route key={index} path={route.path} element={route.element} />
          </>
        ))}
      </Routes>
    </>
  );
}
