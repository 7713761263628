import React from "react";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./routes/routes";
import "./App.css";
import "./styles/global.css";



function App() {

  return (
    <BrowserRouter>
      <Navigation />
    </BrowserRouter>
  );
}

export default App;
