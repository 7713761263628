import React, { useState } from "react";
import Logo from "../assets/Images/Logo.svg";
import Close from "../assets/Images/Copy.svg";
import DownArrow from "../assets/Images/DownArrow.svg";
import Arrow from "../assets/Images/Arrow.svg";
import { useNavigate } from "react-router-dom";

const MenuBar = ({ setOpenMenu }) => {
  const navigate = useNavigate();
  const [showresources, setShowResources] = useState(false);
  const [showWork, setShowWork] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const resources = [
    {
      title: "Human-Centric AI",
      desc: "AI designed to empower and augment human capabilities, placing people at the center of technological innovation.",
      navigate: "/human-centric-ai",
    },
    {
      title: "White Papers",
      desc: "Exploring how human-centric systems can reshape industries and the workforce.",
      navigate: "/white-papers",
    },
    {
      title: "Reports",
      desc: "Comprehensive analysis of the latest technological advancements and industry adoption.",
      navigate: "/reports",
    },
  ];

  const works = [
    {
      title: "Industries",

      items: [
        {
          title: "Energy",
          navigate: "/industries/energy",
        },
        {
          title: "Investments",
          navigate: "/industries/investments",
        },
        {
          title: "Healthcare",
          navigate: "/industries/healthcare",
        },
        {
          title: " Supply Chain",
          navigate: "/industries/heavy-industries",
        },
      ],
    },
    {
      title: "Solutions",

      items: [
        {
          title: `Data Engineering + AI`,
          navigate: "/solutions/data-engineering",
        },
        {
          title: `
          Process Automation + AI`,
          navigate: "/solutions/process-automation",
        },
        {
          title: `
          Product Development + AI`,
          navigate: "/solutions/product-development",
        },
      ],
    },
    {
      title: "Products",

      items: [
        {
          title: "Hushl for Professionals",
          navigate: "/products/hushl-for-professionals",
        },
        {
          title: "Hushl for Founders",
          navigate: "/products/hushl-for-founders",
        },
        {
          title: "Hushl for Enterprises",
          navigate: "/products/hushl-for-enterprises",
        },
      ],
    },
  ];

  const company = [
    {
      title: "About",
      desc: "Dive deeper into our mission, vision, and the core values that drive us forward. Discover how we’re making a difference in the world of AI.",
      navigate: "/about",
    },
    {
      title: "People & Culture",
      desc: "More on our diverse community, shared values, and the vibrant tapestry that makes us who we are.",
      navigate: "/human",
    },
  ];

  const contact = [
    {
      title: "Career",
      desc: "We’re passionate optimists who thrive on collaborating with individuals from across the globe. If you’re seeking research insights or need advice, do reach out.",
      navigate: "/careers",
    },
    {
      title: "Partner with us",
      desc: "Join forces with us to create innovative solutions and drive meaningful impact.",
      navigate: "/partner",
    },
    {
      title: "Reach out",
      desc: "",
      navigate: "/reports",
    },
  ];
  return (
    <div className="hushl-menubar">
      <div className="hushl-menubar-header">
        <div
          onClick={() => {
            setOpenMenu(false);
          }}
          className="logo"
        >
          <img src={Logo} alt="" />
        </div>
        <img onClick={() => setOpenMenu(false)} src={Close} alt="" />
      </div>
      <div className="hushl-menu-bar-list">
        <div className="hushl-menu-bar-item">
          <div
            className="menubar-item-wrap"
            onClick={() => {
              setShowResources(!showresources);
              // close other menus
              setShowWork(false);
              setShowCompany(false);
              setShowContact(false);
            }}
          >
            <div
              className={
                showresources
                  ? "hushl-menu-bar-item-title active"
                  : "hushl-menu-bar-item-title"
              }
            >
              <h1>Resources</h1>
              <img src={DownArrow} alt="" />
            </div>
            <p>
              Discover valuable insights on HCAI, alongside whitepapers and
              reports, to empower your understanding and decision-making across
              diverse sectors
            </p>
          </div>
          {showresources && (
            <>
              <div className="hover-content-right-items hover-content-right-items-menubar">
                {resources.map((resource, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      navigate(resource.navigate);
                    }}
                    className="hover-content-right-item"
                  >
                    <h1 className="style-subtitle1" style={{ color: "#000" }}>
                      {resource.title}
                    </h1>
                    <p className="style-subtitle3">{resource.desc}</p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="hushl-menu-bar-item">
          <div
            className="menubar-item-wrap"
            onClick={() => {
              setShowWork(!showWork);

              // close other menus
              setShowResources(false);
              setShowCompany(false);
              setShowContact(false);
            }}
          >
            <div
              className={
                showWork
                  ? "hushl-menu-bar-item-title active"
                  : "hushl-menu-bar-item-title"
              }
            >
              <h1>Work</h1>
              <img src={DownArrow} alt="" />
            </div>
            <p>Empowering Humanity, Unraveling Solutions</p>
          </div>
          {showWork && (
            <>
              <div className="hover-content-right-items hover-content-right-items-menubar">
                {works.map((work, index) => (
                  <div key={index} className="hover-content-right-item">
                    <h1 className="style-subtitle1" style={{ color: "#000" }}>
                      {work.title}
                    </h1>
                    <div>
                      {work.items.map((item, index) => (
                        <div
                          onClick={() => {
                            navigate(item.navigate);
                          }}
                          className="hover-content-right-item-link"
                        >
                          {item.title}
                          <img src={Arrow} alt="" />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="hushl-menu-bar-item">
          <div
            className="menubar-item-wrap"
            onClick={() => {
              setShowCompany(!showCompany);
              // close other menus
              setShowResources(false);
              setShowWork(false);
              setShowContact(false);
            }}
          >
            <div
              className={
                showCompany
                  ? "hushl-menu-bar-item-title active"
                  : "hushl-menu-bar-item-title"
              }
            >
              <h1>Company</h1>
              <img src={DownArrow} alt="" />
            </div>
            <p>
              Dive deeper into our mission, vision, and the core values that
              drive us forward. Discover how we’re making a difference in the
              world of AI.
            </p>
          </div>
          {showCompany && (
            <>
              <div className="hover-content-right-items hover-content-right-items-menubar">
                {company.map((companu, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      navigate(companu.navigate);
                    }}
                    className="hover-content-right-item"
                  >
                    <h1 className="style-subtitle1" style={{ color: "#000" }}>
                      {companu.title}
                    </h1>
                    <p>{companu.desc}</p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="hushl-menu-bar-item">
          <div
            className="menubar-item-wrap"
            onClick={() => {
              setShowContact(!showContact);
              // close other menus
              setShowResources(false);
              setShowWork(false);
              setShowCompany(false);
            }}
          >
            <div
              className={
                showContact
                  ? "hushl-menu-bar-item-title active"
                  : "hushl-menu-bar-item-title"
              }
            >
              <h1>Contact</h1>
              <img src={DownArrow} alt="" />
            </div>
            <p>
              We’re passionate optimists who thrive on collaborating with
              individuals from across the globe. If you’re seeking research
              insights or need advice, do reach out.
            </p>
          </div>
          {showContact && (
            <>
              <div className="hover-content-right-items hover-content-right-items-menubar">
                {contact.map((contact, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      navigate(contact.navigate);
                    }}
                    className="hover-content-right-item"
                  >
                    <h1 className="style-subtitle1" style={{ color: "#000" }}>
                      {contact.title}
                    </h1>
                    <p>{contact.desc}</p>
                    <div className="cta">
                      know more
                      <img src={Arrow} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
