import React, { useState, useEffect } from "react";
import "./products.css";
import WanderyPreview from "../../../assets/Images/Products/productGrid/wanderyPreview.png";
import AttisPreview from "../../../assets/Images/Products/productGrid/attisPreview.png";
import SparkPreview from "../../../assets/Images/Products/productGrid/sparkPreview.png";
import creatorPreview from "../../../assets/Images/Products/productGrid/createrPreview.png";
import PodcasterPreview from "../../../assets/Images/Products/productGrid/podcasterPreview.png";
import MiniappPreview from "../../../assets/Images/Products/productGrid/miniappPreview.png";

import CreatorMain from "../../../assets/Images/Products/productGrid/creatorMain.png";
import PodcasterMain from "../../../assets/Images/Products/productGrid/podcasterMain.png";
import MiniappMain from "../../../assets/Images/Products/productGrid/miniappMain.png";
import SparkMain from "../../../assets/Images/Products/productGrid/sparkMain.png";
import WanderypMain from "../../../assets/Images/Products/productGrid/wanderyMain.png";
import AttisMain from "../../../assets/Images/Products/productGrid/attisMain.png";
import CasterLogo from "../../../assets/Images/Products/productsMainLogo/caster.svg";
import PilotLogo from "../../../assets/Images/Products/productsMainLogo/pilot.svg";
import MiniappLogo from "../../../assets/Images/Products/productsMainLogo/miniapp.svg";
import SparkLogo from "../../../assets/Images/Products/productsMainLogo/spark.svg";
import WanderyLogo from "../../../assets/Images/Products/productsMainLogo/wandery.svg";
import AttisLogo from "../../../assets/Images/Products/productsMainLogo/attis.svg";

import { useNavigate } from "react-router-dom";

const hushl_founders = [
  {
    id: 1,
    name: "Wandery",
    logo: WanderyLogo,
    previewImg: WanderyPreview,
    mainImg: WanderypMain,
    title: "Reimagine travel with generative AI.",
    desc: "Wandery.ai helps streamline family travel planning with child-friendly recommendations. Don't be constrained by a search box. Ask for exactly what you want and get some helpful prompts along the way.",
    type: "HUSHL FOR FOUNDERS",
    places: "EMEA",
  },
  {
    id: 2,
    name: "Spark",
    logo: SparkLogo,
    previewImg: SparkPreview,
    mainImg: SparkMain,
    title: "Quiet Elegance Meets Luxury.",
    desc: "The home of elite dating where unique, luxurious experiences meet romance.  With Spark, experience extraordinary encounters that mark the beginning of unforgettable journeys.",
    type: "HUSHL FOR FOUNDERS",
    places: "SINGAPORE",
  },
  {
    id: 3,
    name: "Attis",
    logo: AttisLogo,
    previewImg: AttisPreview,
    mainImg: AttisMain,
    title: "An AI Preventive Health System.",
    desc: "Attis Bot is your personal AI librarian, giving you instant access to mental health resources.",
    type: "HUSHL FOR FOUNDERS",
    places: "INDIA",
  },
];

const hushl_professionals = [
  {
    id: 1,
    name: "Pilot",
    logo: PilotLogo,
    previewImg: creatorPreview,
    mainImg: CreatorMain,
    title:
      "Build distribution on the internet through organic, insightful content.",
    desc: "Unleashing the power of AI to craft compelling tweets, LinkedIn posts, and blogs, Hushl Pilot is your digital companion for impactful online presence. It's not just about saving time, it's about enhancing your voice in the digital world. ",
    type: "HUSHL FOR PROFESSIONALS",
    places: "WORLDWIDE",
  },
  {
    id: 2,
    name: "Podcaster",
    logo: CasterLogo,
    previewImg: PodcasterPreview,
    mainImg: PodcasterMain,
    title: "From concept to distribution.",
    desc: "Hushl Podcast Program helps thought leaders get their content out into the world.",
    type: "HUSHL FOR PROFESSIONALS",
    places: "INDIA",
  },
  {
    id: 3,
    name: "Miniapp",
    logo: MiniappLogo,
    previewImg: MiniappPreview,
    mainImg: MiniappMain,
    title: "Get quality leads for your business.",
    desc: "Build a Gen-AI powered lead magnet and share it on your website - in less than 1 minute.",
    type: "HUSHL FOR PROFESSIONALS",
    places: "WORLDWIDE",
  },
];

const Products = () => {
  const [activeTab, setActiveTab] = useState("founders");
  const [selectedMainImg, setSelectedMainImg] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedDesc, setSelectedDesc] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const firstItem = (tabItems, id) => tabItems.find((item) => item.id === id);
    if (activeTab === "founders") {
      const item = firstItem(hushl_founders, 1);
      setSelectedMainImg(item.mainImg);
      setSelectedTitle(item.title);
      setSelectedDesc(item.desc);
      setSelectedLogo(item.logo);
    } else if (activeTab === "professionals") {
      const item = firstItem(hushl_professionals, 1);
      setSelectedMainImg(item.mainImg);
      setSelectedTitle(item.title);
      setSelectedDesc(item.desc);
      setSelectedLogo(item.logo);
    }
  }, [activeTab]);

  const handleImageClick = (item) => {
    setSelectedMainImg(item.mainImg);
    setSelectedTitle(item.title);
    setSelectedDesc(item.desc);
    setSelectedLogo(item.logo);
  };

  const renderPreviews = (items) => {
    return (
      <div className="preview-grid">
        {items.map((item) => (
          <div
            key={item.id}
            className={`preview-item ${
              item.id === 1 ? "full-width" : "half-width"
            }`}
            onClick={() => handleImageClick(item)}
          >
            <img src={item.previewImg} alt={item.name} />
            <div className="preview-info">
              <div
                className="preview-info-item"
                onClick={() => {
                  navigate("/human");
                }}
              >
                {item.places}
              </div>
              <div className="preview-info-item secondary-preview-info-item">
                {item.type}
              </div>
            </div>
            <div className="preview-logo">
              <img src={item.logo} alt={item.name} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="products-wrapper">
      <div className="products-component">
        <div className="products-component-left">
          <h2 className="style-title2">
            We solve for problems of the future,
            <p>
              Keeping{" "}
              <span className="gradient-text">humans at the center.</span>{" "}
            </p>
          </h2>
          <div className="tabs-preview">
            <div
              className={
                activeTab === "founders" ? "active-tab tab-item" : "tab-item"
              }
              onClick={() => setActiveTab("founders")}
            >
              <p className="mobile-tab">For Founders</p>
              <p>Hushl for Founders</p>
            </div>
            <div
              className={
                activeTab === "professionals"
                  ? "active-tab tab-item"
                  : "tab-item"
              }
              onClick={() => setActiveTab("professionals")}
            >
              <p className="mobile-tab">For Professionals</p>
              <p>Hushl for Professionals</p>
            </div>
          </div>
          <div className="preview-container">
            {activeTab === "founders" && renderPreviews(hushl_founders)}
            {activeTab === "professionals" &&
              renderPreviews(hushl_professionals)}
          </div>
        </div>
        <div className="products-component-right">
          <div className="products-component-right-img">
            {selectedMainImg && <img src={selectedMainImg} alt="Main" />}
          </div>
          <div className="products-right-content-logo">
            <img src={selectedLogo} alt="" />
          </div>
          <div className="products-component-right-content">
            <h1 className="style-title2">{selectedTitle}</h1>
            <p className="style-nav-header">{selectedDesc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
