import React from 'react';
import './GridCard.css'

export const GridCard = ({ word, text, para }) => {
  return (
    <div className='grid-card'>
      <BlurText text={word} />
      <div className='grid-card-item-text-1' >
        <h1 className='style-subheader'>{text}</h1>
      </div>
      <div className='grid-card-item-text-2'>
        <p className='style-subtitle1' style={{color:"#595959"}}>{para}</p>
      </div>
    </div>
  )
}

export const FlexCard = ({ desc, text }) => {
  return (
    <div className='flex-card-container'>
      <div className='flex-card-1'>
        <h1 className='flex-card-1-paragraph'>{desc}</h1>
      </div>
      <div className='flex-card-2'>
        <h1 className='flex-card-2-paragraph'>{text}</h1>
      </div>
    </div>
  )
}

export const BlurText = ({ text}) => {

  return (
    <div className='blur-text'>
      <p>{text}</p>
    </div>
  )
}