import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Arrow from "../../assets/Images/Arrow.svg";
import Deloitte from "../../assets/Images/Reports/Deloitte.png";
import McKinsey1 from "../../assets/Images/Reports/McKinsey1.png";
import McKinsey2 from "../../assets/Images/Reports/McKinsey2.png";

import "./resources.css";

const Reports = () => {
  const data = [
    {
      name: `Artificial Intelligence, <br /> <span>Delloite 2018</span>`,
      link: "https://www2.deloitte.com/content/dam/Deloitte/nl/Documents/deloitte-analytics/deloitte-nl-data-analytics-artificial-intelligence-whitepaper-eng.pdf",
      img: Deloitte,
    },
    {
      name: `The state of AI in 2021, <br /> <span>McKinsey Analytics 2021</span>`,
      link: "https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/McKinsey%20Analytics/Our%20Insights/Global%20survey%20The%20state%20of%20AI%20in%202021/Global-survey-The-state-of-AI-in-2021.pdf",
      img: McKinsey1,
    },
    {
      name: `The state of AI in
      2023, <br /> <span> QuantumBlack, AI by McKinsey,2023</span>`,
      link: "https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/McKinsey%20Analytics/Our%20Insights/Global%20survey%20The%20state%20of%20AI%20in%202021/Global-survey-The-state-of-AI-in-2021.pdf",
      img: McKinsey2,
    },
  ];
  return (
    <>
      <Navbar />
      <div className="resources reports">
        <h1 className="style-title2">Reports</h1>
        <p className="style-subtitle2">Stay up to date with the latest changes in the AI landscape.</p>

        <div className="report-cards">
          {data.map((item, index) => {
            return (
              <div className="report-card" key={index}>
                <div className="report-card-img">
                  <img src={item.img} alt="" />
                </div>
                <div className="report-card-text">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.name,
                    }}
                  ></p>
                </div>
                <button
                  onClick={() => {
                    window.open(item.link, "_blank", "noreferrer");
                  }}
                >
                  Read Report <img src={Arrow} alt="" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Reports;
