import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import DownArrowIcon from "../../assets/Images/DownArrowIcon.svg";
import Heavy1 from "../../assets/Images/Industries/Heavy1.png";
import Heavy2 from "../../assets/Images/Industries/Heavy2.png";
import "./internal.css";
import SolutionsContact from "../../components/Contact/SolutionsContact";
import { useNavigate } from "react-router-dom";
const Energy = () => {
  const navigate = useNavigate();
  const readMore = useRef(null);

  const scrollToReadMore = () => {
    if (readMore.current) {
      readMore.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar />
      <div className="internal-pages-landing">
        <div className="internal-pages-left">
          <h1 className="style-title2">
            HCAI is ushering in a new era in <span className="gradient-text"> _Supply Chain</span>
          </h1>

          <div className="buttons">
            <button
              onClick={() => {
                navigate("/white-papers");
              }}
            >
              Download Paper
            </button>
            <button onClick={scrollToReadMore} className="secondary">
              <img src={DownArrowIcon} alt="" />
              Read more
            </button>
          </div>
        </div>
        <div className="internal-pages-right">
          <img src={Heavy1} alt="" />
        </div>
      </div>
      <div className="internal-second-section" ref={readMore}>
        <img src={Heavy2} alt="" />
        <div className="internal-second-section-content">
          <h1 className="style-subheader">
            HCAI is set to quietly revolutionize Heavy Industries
          </h1>
          <p className="style-subtitle2">
            Supply Chain is on the verge of a subtle yet significant
            transformation as Human-Centered AI becomes an integral part of
            operations. This shift is not just about automating processes, but
            about optimizing them to increase efficiency, reduce costs, and
            improve service delivery. It’s about leveraging AI to analyze
            complex data, predict trends, and make informed decisions. However,
            the success of this transformation hinges on the expertise of
            technology teams. These professionals play a crucial role in guiding
            the development and implementation of AI solutions, ensuring they
            align with organizational goals, adhere to industry standards, and
            deliver tangible value. As we navigate this digital transition, the
            role of these technology teams becomes increasingly vital, serving
            as the backbone for successful AI integration in Supply Chain .
          </p>
        </div>
      </div>
      <div className="internal-grad-section">
        <h1 className="style-subheader">
          “Artificial Intelligence will revolutionize the supply chain in ways
          that haven’t even been thought of yet.” 
        </h1>
      </div>
      <div className="internal-contact">
        <SolutionsContact />
      </div>
      <Footer />
    </>
  );
};

export default Energy;
