import React from "react";
import BgGif from "../../assets/Images/Bg.gif";
import "./styles.css";
import Navbar from "../../components/Navbar";
import Arrow from "../../assets/Images/Arrow.svg";
import Team1 from "../../assets/Images/Team1.png";

import Team2 from "../../assets/Images/Team2.png";
import Team3 from "../../assets/Images/Team3.png";
// import Team1 from "../../assets/Images/Team1.png";
// import Team2 from "../../assets/Images/Team2.png";
// import Team3 from "../../assets/Images/Team3.png";

// import LinkedinBlack from "../../assets/Images/LinkedinBlack.svg";

import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import Products from "./components/Products";
// import Clients from "./components/Clients";
import HomeContact from "../Contact/component/HomeContact";
// import OurTeam from "./components/OurTeam";

const Home = () => {
  const navigate = useNavigate();
  document.body.style.backgroundColor = "#fff";
  return (
    <>
      <Navbar />
      <div className="landing-section">
        <div className="bg-landing">
          <img src={BgGif} alt="" />
        </div>
        <div className="landing-text">
          <h1 className="style-title1">
            Only Human-Centric AI is <br /> <span> _Sustainable</span>
          </h1>
          <p className="style-subtitle-landing">
            At Hushl, we're a passionate team of tech innovators <br />{" "}
            dedicated to harnessing AI for positive impact.
          </p>
        </div>
        <div className="home-navogation">
          <div className="home-navogation-inner">
            <div className="home-nav-item home-read-more-about-text">
              <p
                onClick={() => {
                  navigate("/human-centric-ai");
                }}
                className="cta-type1-text"
              >
                Read more about <br />
                <span>_human centric AI</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
            <div className="home-nav-item">
              <p
                className="cta-type1-text"
                onClick={() => {
                  navigate("/careers");
                }}
              >
                Explore working <br />
                <span>_with us</span>
              </p>
              <img src={Arrow} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Products />

      {/* <OurTeam /> */}
      <div className="about-us-team">
        <div className="impact-second-section-top">
          <h1 className="style-title2">
            The minds behind <br />
            <span> _Hushl</span>
          </h1>
          <p className="style-subtitle2">
            Behind Hushl is a diverse team of AI experts, engineers,
            researchers, and enthusiasts united by a shared vision. With a
            wealth of experience and expertise, our team is dedicated to
            creating AI solutions that make a difference in the world.
          </p>
        </div>
        <div className="about-team-cards">
          <div
            className="about-team-card"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <div className="about-team-card-img">
                <img src={Team1} alt="" className="w-full h-auto" />
              </div>
              <div className="about-team-content">
                <h1 className="style-subheader">Shariq Salar</h1>
                <p className="font-medium text-gray-600">
                _Chief Executive Officer
                </p>
              </div>
            </div>
            <div className="about-team-content">
              <p
                style={{
                  fontSize: "16px",
                }}
              >
               Philomath by nature, Shariq earned his last two masters' from the National Law School of India, 
              besides his earlier degrees in Finance, Systems and Engineering. With a body of work across 
              Tech Ventures, Private Equity & Healthcare, Shariq has had a string of successful leadership stints. 
              He has lived / worked in Europe, the Nordics, West Asia and India.
              </p>
            </div>
          </div>
          <div
            className="about-team-card"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
              }}
            >
              <div className="about-team-card-img">
                <img src={Team2} alt="" className="w-full h-auto" />
              </div>
              <div className="about-team-content">
                <h1 className="style-subheader">Aakarsh Yadav</h1>
                <p className="font-medium text-gray-600">
                _Chief Technology Officer
                </p>
              </div>
            </div>
            <div className="about-team-content">
              <p
                style={{
                  fontSize: "16px",
                }}
              >
               After spending two master's terms at IIT Kharagpur delving deep into diverse engineering disciplines, 
              Aakarsh went on to work with global big tech firms (Rakuten) and funded startups (5paisa). 
              A serial innovator, Aakarsh thrives on building complex tech forward solutions. He has lived 
              /worked in India, Russia, Australia, South East Asia.
              </p>
            </div>
          </div>

          <div
            className="about-team-card"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <div className="about-team-card-img">
                <img src={Team3} alt="" className="w-full h-auto" />
              </div>
              <div className="about-team-content">
                <h1 className="style-subheader">Adithya Narayanan</h1>
                <p className="font-medium text-gray-600">
                  _Chief Product Officer
                </p>
              </div>
            </div>
            <div className="about-team-content">
              <p
                style={{
                  fontSize: "16px",
                }}
              >
                An alumnus of Harvard's Kennedy School, Adithya has an enduring
                passion for design-led impact through education & technology. He
                has previously been a Fellow at Teach For India, business
                architect at IDEO, and investment associate at Draper Kaplan
                Richards. He has worked / lived across India, West Asia, East
                Africa and North America.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <Clients /> */}

      <HomeContact />

      <Footer />
    </>
  );
};

export default Home;
