import React from 'react';
import './LastFlexCard.css'

const LastFlexCard = ({date,text,para,src}) => {
  return (
    <div className='last-flex-card'>
      <div className="last-flex-right">
        <div className='text-card-blur-text'>
          <p>{date}</p>
        </div>
        <h1 className="style-subheader" id='text-subtitle'>{text}</h1>
        <p className="style-subtitle2" style={{color:"#595959"}}>{para}</p>
        <button className='last-buttons'>
        Watch now
        </button>
      </div>
      <div className="last-flex-left">
        <iframe
          width="520"
          height="315"
          src={src}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default LastFlexCard
