import React from "react";
import "./styles.css";
import Lottie from "lottie-react";
import cubes from "../../assets/animation/cubes.json";
// import { Carousel } from "primereact/carousel";
import Icon from "../../assets/Images/founders/icon.svg";
import Testimonial from "../../assets/Images/founders/david.png";
import Step from "../../assets/Images/founders/step.png";
import FoundersNavbar from "./FoundersNavbar";

const FoundersProgram = () => {
  return (
    <div className="founders-page">
      <FoundersNavbar />
      <div className="founders-section-1">
        <div className="founders-section-1-flex">
          <div className="founders-section-animation">
            <Lottie animationData={cubes} loop={true} />
          </div>
          <div className="founders-section-text">
            <h1>
              <span> The world craves bold</span> <br /> ideas.What’s yours?
            </h1>
            <p>
              <span> The Founders Program</span> empowers proven leaders to
              transform their visions into market-dominating ventures.
            </p>
            <button className="button">Become a founder</button>
          </div>
        </div>
        <div className="founder-section-text-below">
          <p>
            Your groundbreaking vision holds the potential to disrupt the
            market. But bringing it to life requires more than just an idea.
            <span>
              -The Founders Program is designed to help you do just that.
            </span>
          </p>
          <p>
            Our <span>curated launchpad</span> provides the structured approach
            and resources needed to navigate the complexities of launching and
            scaling a successful tech business.
          </p>
        </div>
      </div>
      <div className="founders-section-2">
        <div className="founders-section-2-text">
          <span>Why us?</span>
          <h1>
            Experienced Leader + Disruptive Product = <span> What's Next?</span>
          </h1>
          <p>
            <span>
              {" "}
              The Founders Program is your framework for building world-changing
              companies.
            </span>{" "}
            Through a comprehensive program designed specifically for you, we
            guide you through every step of the journey, from rapid validation
            and refinement to building a market leader.
          </p>
        </div>
        <div className="founders-section-2-card">
          <div className="wrapper">
            <div className="outer">
              <div className="card" style={{ "--delay": -1 }}>
                <img src={Step} alt="" />
                <p>01 - Validate Your Idea </p>
                <h1>
                  Whether it's your vision or one we uncover together, we'll
                  ensure it has the potential to disrupt the market and become a
                  game-changer.
                </h1>
              </div>
              <div className="card" style={{ "--delay": 0 }}>
                <img src={Step} alt="" />
                <p>02 - Build Your Foundation </p>
                <h1>
                  We'll guide you through establishing a strong legal and
                  operational structure to support your company's growth.
                </h1>
              </div>
              <div className="card" style={{ "--delay": 1 }}>
                <img src={Step} alt="" />
                <p>03 - Build Your Product </p>
                <h1>
                  Our team of platform and tech specialists work alongside you
                  to turn your concept into a real product that's ready for
                  market.
                </h1>
              </div>
              <div className="card" style={{ "--delay": 2 }}>
                <img src={Step} alt="" />
                <p>04 - Go to Market </p>
                <h1>
                  We'll help you launch strategically, gather valuable user
                  feedback, and acquire your first customers to gain market
                  traction..
                </h1>
              </div>
              <div className="card" style={{ "--delay": 2 }}>
                <img src={Step} alt="" />
                <p>05 - Take Control </p>
                <h1>
                  Beyond launch, we provide ongoing support for the first year
                  to ensure a smooth transition to independent operation and
                  continued success.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="founders-section-3">
        <h1>
          <span> Setting The Board</span>
        </h1>
        <p>
          Great products are born from great ideas. Here’s how we’ll help you
          validate yours and get the ball rolling on your next venture
        </p>
        <div className="foundation-cards">
          <div className="foundation-card">
            <img src={Icon} alt="" />
            <h1>Problem Discovery</h1>
            <p>
              You actively engage by expressing interest in specific problems
              and proposing solutions.
            </p>
          </div>
          <div className="foundation-card">
            <img src={Icon} alt="" />
            <h1>Foundry Assessment </h1>
            <p>
              Your platform activity and responses are analyzed to identify your
              strengths and potential markets.This assessment leverages machine
              learning for a data-driven evaluation
            </p>
          </div>

          <div className="foundation-card">
            <img src={Icon} alt="" />
            <h1>Collaborative Refinement </h1>
            <p>
              You refine your chosen problem and solution through discussions
              with our team and external consultants
            </p>
          </div>
          <div className="foundation-card">
            <img src={Icon} alt="" />
            <h1>Validation & Conviction Building</h1>
            <p>
              This process helps you solidify your entrepreneurial vision and
              build confidence in your solution.
            </p>
          </div>
          <div className="foundation-card">
            <img src={Icon} alt="" />
            <h1>Product Roadmap</h1>
            <p>
              Collaborate with us to develop a dynamic product roadmap informed
              by the data analysis and problem understanding.
            </p>
          </div>
        </div>
      </div>
      <div className="founders-section-4">
        <div className="founders-section-4-left">
          <h1>
            Become a <br /> <span> Founder</span>
          </h1>
          <div className="form">
            <input type="text" placeholder="Full Name" />
            <input type="text" placeholder="Email" />
            <input type="text" placeholder="Phone Number" />
            <input type="text" placeholder="Company Name" />
            <button className="button">Schedule a Consultation</button>
          </div>
        </div>
        <div className="founders-section-4-right">
          <div className="founders-testimonials">
            <h1>
              “ I streamlined my product development process and launched 2
              months ahead of schedule.”
            </h1>
            <div className="testimonials-name-flex">
              <img src={Testimonial} alt="" />
              <div className="testimonials-name">
                <p>David Hamlin</p>
                <span>Founder & CEO, Wandery</span>
              </div>
            </div>
          </div>
          {/* <div className="founders-testimonials">
            <h1>
              " I streamlined my product development process and launched 2
              months ahead of schedule.”
            </h1>
            <div className="testimonials-name-flex">
              <img src={Testimonial} alt="" />
              <div className="testimonials-name">
                <p>David Hamlin</p>
                <span>Founder & CEO, Wandery</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FoundersProgram;
