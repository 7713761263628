import React from "react";
import Logo from "../../assets/Images/founders/logo.svg";

const FoundersNavbar = () => {
  return (
    <div className="founders-nav">
      <div className="founder-logo">
        <img
          style={{
            visibility: "hidden",
          }}
          src={Logo}
          alt=""
        />
      </div>
      <div className="links">
        <p>Why us</p>
        <p>Foundation</p>
        <p>Become a founder</p>
      </div>
    </div>
  );
};

export default FoundersNavbar;
