import React from "react";
import "./HumanAI.css";
import video from "../../assets/Images/Human-centric-ai.gif";
import { GridCard, FlexCard, BlurText } from "./GridCard/GridCard";
import LastFlexCard from "./LastFlexCard/LastFlexCard";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const data = [
  {
    id: "Item1",
    word: "EMPOWERMENT",
    text: "People-First AI",
    para: "Solutions that empower humans, not replace them.",
  },
  {
    id: "Item2",
    word: "INTEGRITY",
    text: "Ethical AI",
    para: "Built with fairness and responsible practices at the core.",
  },
  {
    id: "Item3",
    word: "CLARITY",
    text: "Transparent AI",
    para: "No black boxes. Clear, understandable, and trustworthy.",
  },
  {
    id: "Item4",
    word: "LONGEVITY",
    text: "Sustainable AI",
    para: "Designed for impact & long term continuity.",
  },
];

const flexData = [
  {
    desc: "Our AI enhances creativity, sharpens decision-making, and boosts productivity, allowing people to excel in uniquely human ways.",
    text: "From Automation to Augmentation",
  },
  {
    desc: "We design AI to facilitate meaningful interactions and support genuine relationships, bringing people together in the digital age.",
    text: "Technology that Unites",
  },
  {
    desc: "We embed fairness, transparency, and respect for privacy into every algorithm, ensuring our technology aligns with human values.",
    text: "Ethical AI by Design",
  },
  {
    desc: "Our systems provide valuable insights and options, while always leaving the final decision to humans, respecting autonomy and diverse perspectives.",
    text: "Informed Choices, Human Decisions",
  },
];

const lastData = [
  { 
    date:"Nov 24, 2020",
    text: "What is human-centered artificial intelligence?",
    para: "When we discuss a human approach to AI, what exactly do we mean? Learn about the vision of Stanford HAI.",
    src:"https://www.youtube.com/embed/4W2kXBBFDw4?si=uO_XLWb0oUuqx35u",
  },
  { 
    date:"Oct 7, 2021",
    text: "Human-Centric AI for Computer Vision and Machine Autonomy",
    para: "Understand and improve human-centric properties such as interpretability, steerability, generalizability & fairness",
    src:"https://www.youtube.com/embed/WJSXpEkNCfs?si=JUPJB-9nSn_DHUue",
  },
  { 
    date:"May 5, 2023",
    text: "Ensuring Human Control While Increasing Automation",
    para: "A new synthesis is emerging that integrates AI technologies with Human-Computer Interaction to produce Human-Centered AI.",
    src: "https://www.youtube.com/embed/arOzDH9uALM?si=WPNgy8XbGvWABJE4",
  },
  { 
    date:"Jan 27, 2021",
    text: "Human-centric AI challenges and opportunities | AI & Policy | Sabrina Kirrane",
    para: "The Applied Machine Learning Days channel features talks and performances from the Applied Machine Learning Days.",
    src:"https://www.youtube.com/embed/0mAlZmYi4tQ?si=KUFVQfr8uu0ZyFfx",
  },
]

const HumanAI = () => {
  return (
    <>
      <Navbar hide={true} />
      <div className="HumanAI-section">
        <div className="HumanAI-section-card">
          <img src={video} alt="video" className="HumanAI-image" />
          <div className="overlay"></div>
          <div className="HumanAI-text">
            <BlurText text={"KEEPING HUMANS AT THE CENTRE"} />
            <br />
            <h1 className="style-title2">
              Human-Centric Artificial
              <br /> Intelligence
            </h1>
          </div>
        </div>
        <div className="HumanAI-section-card-2">
          {/* <BlurText text={"BRIDGING TECHNOLOGY AND HUMANITY"}/> */}
          <div className="HumanAI-section-card-2-Text">
            <div className="HumanAI-section-card-2-Text-1">
              <p className="style-subheader">
                {/* At Hushl.ai, we weave empathy into the fabric of AI, crafting
                tools that truly resonate with human needs. This synergy of
                cutting-edge technology and deep human understanding results in
                solutions that are not just innovative, but transformative and
                compassionate. */}
                At Hushl.ai, we weave empathy into the fabric of AI, crafting tools that truly resonate with human needs.
              </p>
            </div>
            <div className="HumanAI-section-card-2-Text-2">
              <p style={{color:"#747474"}} className="style-subtitle2">
                {/* As we navigate this exciting frontier, humans remain the north
                star of our innovation. We're committed to developing AI that
                doesn't just process data, but understands context, learns from
                human interaction, and evolves alongside us. This dedication to
                human-centric design ensures our AI tools are intuitive,
                accessible, and genuinely beneficial for all users. */}
                Our vision extends beyond creating smarter algorithms; we aim to amplify human potential. We're shaping a future where AI and humans collaborate seamlessly, unlocking unprecedented levels of creativity, productivity, and innovation. This is more than technological advancement – it's about enriching the human experience in the digital age.{" "}
              </p>
              <br />
              {/* <p className="style-subtitle2">
                Our vision extends beyond creating smarter algorithms; we aim to
                amplify human potential. We're shaping a future where AI and
                humans collaborate seamlessly, unlocking unprecedented levels of
                creativity, productivity, and innovation. This is more than
                technological advancement – it's about enriching the human
                experience in the digital age.
              </p> */}
            </div>
          </div>
        </div>
        {/* GRID CARDS */}
        <div className="grid-container">
          {data.map((item) => (
            <div className="GridItem" id={item.id} key={item.id}>
              <GridCard word={item.word} text={item.text} para={item.para} />
            </div>
          ))}
        </div>
        {/* FLEX CARDS */}
        <div className="flex-container">
          <div className="flex-card">
            <div className="flex-container-title-1">
              <h1 className="style-flex-title1">
                We Believe Human-Centricity is Crucial in AI Development
              </h1>
            </div>
            <div className="flex-container-title-2">
              <h1 className="style-flex-paragraph">
              Hushl.ai’s approach puts people at the heart of every innovation, ensuring that our technology enhances human capabilities, fosters connections, and respects individual agency all while upholding ethical values.
              </h1>
            </div>
          </div>

          <div className="flex-card-box">
            {flexData.map((item) => (
              <FlexCard desc={item.desc} text={item.text} />
            ))}
          </div>
        </div>

        <div className="last-container-of-page">
          <div className="last-flex-container-of-page">
            {/* <BlurText text={"EXPAND YOUR KNOWLEDGE"}/> */}
            <div className="last-flex-text">
              <div className="last-container-title-1">
                {/* <BlurText text={"EXPAND YOUR KNOWLEDGE"} /> */}
                <h1 className="style-subheader">More on Human-Centric AI</h1>
              </div>
              <div className="last-container-title-2">
                <h1 className="last-flex-paragraph">
                  AI designed to empower and augment human capabilities, placing{" "}
                  <span>people at the centre</span> of technological innovation.
                </h1>
              </div>
            </div>
          </div>
          {/* <LastFlexCard />
          <LastFlexCard />
          <LastFlexCard /> */}
          <div>
          {lastData.map((item) => (
              <LastFlexCard date={item.date} text={item.text} para={item.para} src={item.src}/>
          ))}
          </div>
        </div>
        <br/>
      </div>
      <Footer/>
    </>
  );
};

export default HumanAI;
